<template>
  <div>
    <div class="setting-nav-title">
      {{ $t("paymentLogos.title") }}
      <!-- <el-button class="confirm pull-right" @click="applyNewPayment">{{ $t("newpayment") }}</el-button> -->
    </div>
    <div style="margin-bottom: 20px;" v-if="false">
      <el-button @click="dataDialog = true">CreatePaymentLogo</el-button>
    </div>
    <div class="payment-container" v-loading="loading">
      <nodata v-if="dataList.length === 0" />
      <el-table v-else :data="dataList">
        <el-table-column prop="displayName" label="displayName(Pay Brand)"></el-table-column>
        <el-table-column prop="paymentType" label="paymentType(BrandCode)"></el-table-column>
        <el-table-column prop="tradeType" label="tradeType(API-TradeType)"></el-table-column>
        <el-table-column prop="logos" label="logos">
          <template slot-scope="scope">
            <el-button
              @click="
                dataDialog = true;
                form.paymentType = scope.row.paymentType;
              "
              type="text"
              size="small"
              >ADDNEWSIZE</el-button
            >
            <p class="payment-img-container" v-for="(item, index) in scope.row.logos" :key="index">
              <span style="font-weight: bold; font-size: 20px;;">{{ index }}:</span>
              <img :src="item" :alt="index" style="height:60px;display: block;" />
              <el-button
                @click="
                  dataDialog = true;
                  form.paymentType = scope.row.paymentType;
                  form.logoSize = index;
                  form.editKey = true;
                "
                type="text"
                size="small"
                >EDIT</el-button
              >
              <el-button @click="deleteData(scope.row.paymentType, index)" type="text" size="small">DELETE</el-button>
            </p>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="" :visible.sync="dataDialog" v-loading="loading" @close="handelDialogClose">
      <el-form :model="form" ref="form">
        <el-form-item label="paymentType" prop="paymentType" required>
          <el-input v-model="form.paymentType" disabled></el-input>
        </el-form-item>

        <el-form-item label="logoSize" prop="logoSize" required>
          <el-input v-model="form.logoSize" :disabled="form.editKey"></el-input>
        </el-form-item>

        <el-form-item label="logoFile" prop="logoFile" required>
          <div style="text-align: left;width: 100%;clear: both;">
            <p style="margin-bottom: 10px;">
              {{ this.form.logoFile ? this.form.logoFile.name : "PLEASE UPLOAD LOGO" }}
            </p>
            <el-upload
              class="avatar-uploader"
              action=""
              :http-request="uploadFile"
              :show-file-list="false"
              v-loading="loading"
            >
              <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar" /> -->
              <!-- <i class="el-icon-plus avatar-uploader-icon"></i> -->

              <el-button type="primary" size="mini">UPLOAD</el-button>
            </el-upload>
          </div>
        </el-form-item>
        <div style="text-align: right;">
          <el-button @click="submitData()">Submit</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getPaymentTypeLogos, updatePaymentTypeLogo, deletePaymentTypeLogo, uploadPaymentTypeLogo } from "api/tenant";
export default {
  data() {
    return {
      loading: false,
      dataDialog: false,
      paylist: [],
      tenantList: [],
      paymentClassList: [],
      dataList: [],
      form: {
        paymentType: "",
        logoSize: "",
        logoFile: "",
        editKey: false
      }
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    uploadFile(e) {
      console.log(e);
      this.form.logoFile = e.file;
    },
    getList() {
      this.loading = true;
      return getPaymentTypeLogos({ pageSize: 9999 }).then(res => {
        console.log(res);
        this.loading = false;
        if (res.statusCode == 200) {
          this.dataList = res.result;
        }
      });
    },
    handelDialogClose() {
      this.form = {
        paymentType: "",
        logoSize: "",
        logoFile: "",
        editKey: false
      };
      this.$refs.form.resetFields();
    },

    submitData() {
      this.$refs.form.validate(valid => {
        console.log(valid);
        if (valid) {
          this.loading = true;
          let xhr;

          const formData = new FormData();
          formData.append("logoFile", this.form.logoFile);
          formData.append("paymentType", this.form.paymentType);
          formData.append("logoSize", this.form.logoSize);

          if (this.form.editKey) {
            xhr = updatePaymentTypeLogo(formData);
          } else {
            xhr = uploadPaymentTypeLogo(formData);
          }

          xhr
            .then(res => {
              this.loading = false;
              console.log(res);
              if (res.statusCode == 200) {
                // this.tenantList = res.result;
                this.dataDialog = false;
                this.getList();
              }
            })
            .catch(err => {
              this.loading = false;
            });
        }
      });
    },
    deleteData(paymentType, logoSize) {
      this.$confirm(`Delete ${paymentType} ${logoSize} LOGO, Confirm?`, "warning", {
        type: "warning"
      })
        .then(() => {
          console.log(paymentType, logoSize);
          // this.$message({
          //   type: 'success',
          //   message: '删除成功!'
          // });
          this.loading = true;
          deletePaymentTypeLogo({ paymentType, logoSize })
            .then(res => {
              this.loading = false;
              console.log(res);
              if (res.statusCode == 200) {
                this.getList().then(res => {
                  console.log(res);
                  this.loading = false;
                });
              }
            })
            .catch(err => {
              this.loading = false;
            });
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.notification-container {
  margin: 16px 0;
}

.payment-container {
  background: #fffffe;
  box-shadow: 0 2px 12px 0 rgba(103, 95, 119, 0.1);
  border-radius: 8px;
  padding: 0 24px;
}

.payment-item {
  @include flex();

  padding: 16px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  &:last-child {
    border: none;
  }

  .payment-img-container {
    width: 122px;
    height: 80px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    position: relative;
    border-radius: 8px;

    & > img {
      width: 90px;
      height: 54px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .payment-content {
    flex: 1;
    padding: 0 30px;

    .payment-name {
      font-size: 16px;
      font-weight: 700;
      line-height: 23px;
    }

    .payment-rate {
      font-size: 12px;
    }
  }

  .payment-status {
    line-height: 24px;

    .payment-status-image {
      width: 20px;
      height: 20px;
      vertical-align: middle;
    }

    .status-text {
      font-size: 14px;
      color: #2aa76c;
      font-weight: 700;
      margin-left: 10px;
    }
  }
}
</style>
